<template>
  <div class="home">
    <div class="newgrid">
      <div
        class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-full home-content"
      >
        <div class="homepage-title">
          <h1 class="homepage-title__text utopia-blk-headline">
            Duck <span class="utopia-dis-italic">or&nbsp;</span> Rabbit<span
              class="utopia-dis-italic"
              >?</span
            >
          </h1>
        </div>
      </div>
    </div>
    <div class="newgrid">
      <div class="grid-block grid-block-left-margin-1col grid-block-2col grid-medium-full">
        <div class="homepage-standfirst">
          <p class="homepage-standfirst__text utopia-cap-italic">
            What’s that all about? Wittgenstein’s high-falutin’ thought experiment about bi-stabile
            objects tried to demonstrate how it was impossible to conceive of two things
            simultaneously, but that’s exactly why it’s a great symbol for us.
          </p>
        </div>
      </div>
      <img
        class="homepage-duckrabbit__image homepage-duckrabbit__image-sm"
        src="@/assets/images/home-duckrabbit_sm.png"
        alt="A duck and a rabbit"
      />
      <img
        class="homepage-duckrabbit__image homepage-duckrabbit__image-md"
        src="@/assets/images/home-duckrabbit_md.png"
        alt="A duck and a rabbit"
      />
    </div>

    <div class="newgrid newgrid-no-row-gap main-body">
      <div class="grid-block grid-block-2col homepage-duckrabbit">
        <img
          class="homepage-duckrabbit__image homepage-duckrabbit__image-xl"
          src="@/assets/images/home-duckrabbit_xl.png"
          alt="A duck and a rabbit"
        />
        <img
          class="homepage-duckrabbit__image homepage-duckrabbit__image-lg"
          src="@/assets/images/home-duckrabbit_lg.png"
          alt="A duck and a rabbit"
        />
      </div>
      <div class="grid-block grid-block-2col">
        <div class="homepage-body">
          <div class="homepage-body__text">
            <p class="slate-light hover-images">
              We’re not just a design agency or a technology partner, we’re both. It depends how you
              see us - if you see a
              <button
                @mouseover="setImage('duck')"
                @mouseleave="hideImage"
                @click="setImage('duck')"
                v-click-outside="hideImageClickHandler"
                class="hover-duck hover-img slate-medium"
                :class="{ 'hover-img__open': showImage && image === 'duck' }"
              >
                duck
              </button>
              we're a design agency, if you see a
              <button
                @mouseover="setImage('rabbit')"
                @mouseleave="hideImage"
                @click="setImage('rabbit')"
                v-click-outside="hideImageClickHandler"
                class="hover-rabbit hover-img slate-medium"
                :class="{ 'hover-img__open': showImage && image === 'rabbit' }"
              >
                rabbit
              </button>
              you need medical help. But seriously, what we do is
              <button
                @mouseover="setImage('both')"
                @mouseleave="hideImage"
                @click="setImage('both')"
                v-click-outside="hideImageClickHandler"
                class="hover-both hover-img slate-medium"
                :class="{ 'hover-img__open': showImage && image === 'both' }"
              >
                meld the two.
              </button>
            </p>
            <p class="slate-light">
              To say we’re well established is a typically English understatement. We have over 30
              years’ continuous experience in digital design and development.
            </p>
            <p class="slate-light">
              We’re based in London and we’re determined to stay small so we can react quickly and
              do things larger organisations find impossible.
            </p>
            <p class="slate-light">
              Our approach is adaptable, user-centred, design-focused and agile, which helps our
              clients deliver fresh perspectives and bold plans.
            </p>
          </div>
        </div>
      </div>
      <div class="grid-block grid-block-1col homepage-rollovers">
        <div class="homepage-body__image-wrapper">
          <transition-group name="fade" mode="out-in">
            <img
              key="duck"
              class="homepage-body__image"
              v-show="showImage && image === 'duck'"
              :src="duck"
            />
            <img
              key="rabbit"
              class="homepage-body__image"
              v-show="showImage && image === 'rabbit'"
              :src="rabbit"
            />
            <img
              key="both"
              class="homepage-body__image"
              v-show="showImage && image === 'both'"
              :src="both"
            />
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "Home",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  metaInfo() {
    return {
      title: this.siteTitle,
      link: [{ rel: "favicon", href: "./favicon.ico" }],
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
        {
          property: "og:url",
          content: "https://tuimedia.com",
          vmid: "ogUrl",
        },
        {
          property: "og:type",
          content: "website",
          vmid: "ogType",
        },
        {
          property: "og:title",
          content: this.siteTitle,
          vmid: "ogTitle",
        },
        {
          vmid: "ogDescription",
          property: "og:description",
          content:
            "We’re a small, well-established, fiercely user-centred interactive design agency based in London’s Soho. We provide strategy, UX design, research and full end-to-end builds of digital tools.",
        },
        {
          property: "og:image",
          content: "https://www.tuimedia.com" + require("@/assets/images/meta_thumbnail.png"),
          vmid: "ogImage",
        },
        {
          property: "og:image:width",
          content: 128,
          vmid: "ogImageWidth",
        },
        {
          property: "og:image:height",
          content: 128,
          vmid: "ogImageHeight",
        },
        {
          property: "og:site_name",
          content: "Tui Media",
        },
        {
          vmid: "twitterCard",
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:image",
          content: "https://www.tuimedia.com" + require("@/assets/images/meta_thumbnail.png"),
          vmid: "twitterImage",
        },
        {
          name: "twitter:image:alt",
          content: "Tui Duck-Rabbit logo",
          vmid: "twitterImageAlt",
        },
        {
          name: "twitter:title",
          content: this.siteTitle,
          vmid: "twitterTitle",
        },
        {
          vmid: "twitterSite",
          name: "twitter:site",
          content: "@tui_uk",
        },
        {
          vmid: "themeColor",
          name: "theme-color",
          content: "#fce600",
        },
      ],
    };
  },
  data() {
    return {
      image: "",
      duck: require(`@/assets/images/duck.png`),
      rabbit: require(`@/assets/images/rabbit.png`),
      both: require(`@/assets/images/both.png`),
      showImage: false,
      siteTitle: "Tui Media: Independent digital design agency, London.",
    };
  },
  methods: {
    setImage(image) {
      this.showImage = true;
      this.image = image;
    },
    hideImage() {
      this.showImage = false;
      this.image = "";
    },
    hideImageClickHandler() {
      const isMobile = window.matchMedia("(max-width: 600px)").matches;

      if (isMobile) {
        this.hideImage();
      }
    },
  },
};
</script>

<style lang="scss">
.home-content {
  // extra-large
  @media screen and (min-width: $extra-large) {
    margin-top: 64px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
    margin-top: 48px;
    grid-column: 2 / 5;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    margin-top: 9px;
    /*  margin-bottom: 64px; */
  }

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    margin-top: 64px;
    margin-bottom: 24px;
  }
}

.homepage-title {
  text-align: center;

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    text-align: left;
    position: relative;
    &__text {
      margin-top: 16px;
      font-size: 72px;
      line-height: 70px;
      max-width: 80%;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    justify-self: center;
    &__text {
      font-size: 64px;
      line-height: 110px;
    }
  }

  // large
  @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
    justify-self: center;
    &__text {
      font-size: 62px;
      line-height: 88px;
    }
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    justify-self: center;
    &__text {
      font-size: 104px;
      line-height: 110px;
    }
  }
}

.homepage-standfirst {
  // extra-large
  @media screen and (min-width: $extra-large) {
    &__text {
      font-size: 24px;
      line-height: 35px;
    }
  }

  // large
  @media screen and (min-width: $large) {
    &__text {
      font-size: 21px;
      line-height: 32px;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    &__text {
      font-size: 18px;
      line-height: 24px;
    }
  }

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    margin: 0 0 24px 0;

    &__text {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.homepage-body {
  &__text p:not(:last-of-type) {
    margin-bottom: 24px;
  }

  &__text--link {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:visited {
      color: #35223e;
    }
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    &__text {
      font-size: 18px;
      line-height: 27px;
    }
  }

  // large
  @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
    margin-bottom: 27px;

    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    margin-bottom: 0;
    &__text {
      font-size: 15px;
      line-height: 21px;
    }
  }

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    margin-bottom: 32px;
    &__text {
      font-size: 21px;
      line-height: 32px;
    }
  }

  &__image-wrapper {
    position: relative;
    // large, extra-large
    @media screen and (min-width: $large) {
      grid-column: 5 / span 1;
    }
    // medium
    @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      grid-column: 1 / span 2;
      grid-row: 3;
    }

    // small
    @media screen and (max-width: #{ $medium - 1 }) {
      display: none;
    }
  }

  &__image {
    // large, extra-large, medium
    @media screen and (min-width: $medium) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &-sm {
      width: 233px;
      height: 233px;
      position: absolute;
      top: -200px;
      border-radius: 50%;
      border: 8px solid #000;
      display: block;
    }
  }
}

.main-body {
  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    flex-direction: row-reverse;
    margin-top: 110px;
    margin-bottom: 110px;
  }
}

.homepage-duckrabbit {
  overflow: hidden;
  mix-blend-mode: multiply;

  &__image {
    display: none;
  }

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    display: none;
    &__image-sm {
      transform: translateX(16px) translateY(-250px) rotate(-5deg);
      right: 0;
      top: 64px;
      position: absolute;
      display: flex;
      mix-blend-mode: multiply;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    display: none;
    &__image-md {
      transform: rotate(-40deg) translateX(27px);
      mix-blend-mode: multiply;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      width: 115px;
      height: 182px;
    }
  }

  // large
  @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
    justify-content: center;
    display: flex;
    overflow: hidden;
    position: relative;
    min-height: 374px;

    &__image-lg {
      height: 469px;
      width: 296px;
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -158px;
      bottom: -100px;
    }
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    justify-self: center;
    align-self: end;
    height: 438px;

    &__image-xl {
      display: grid;
    }
  }
}

.homepage-rollovers {
  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    display: none;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    width: 40%;
    padding-right: 24px;
  }
}

.hover-images {
  position: relative;
}
.hover-img {
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus,
  &__open {
    cursor: pointer;
    color: $hover-blue;
  }

  &__open {
    @media screen and (max-width: #{ $medium - 1 }) {
      &::before {
        content: "";
        z-index: 2;
        display: block;
        width: 233px;
        height: 233px;
        position: absolute;
        right: 0;
        bottom: calc(100% + 8px);
        border-radius: 50%;
        border: 8px solid #000;
      }

      &.hover-duck::before {
        background-image: url("~@/assets/images/duck.png");
      }
      &.hover-rabbit::before {
        background-image: url("~@/assets/images/rabbit.png");
      }
      &.hover-both::before {
        background-image: url("~@/assets/images/both.png");
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
